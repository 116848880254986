/* tslint:disable */
import { Injectable } from '@angular/core';
import { SilverBaseService } from '../silver-base-service';
import { EnviromentService } from '@core/services/environment/environment.service';
import { SilverRequestBuilder } from '../silver-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddressInfo } from '../models/address-info';
import { CertificateChecksDto } from '../models/certificate-checks-dto';
import { ContactInfo } from '../models/contact-info';
import { Person } from '../models/person';
import { PersonSmsConsentDto } from '../models/person-sms-consent-dto';


/**
 * Silver member personal information.
 */
@Injectable({
  providedIn: 'root',
})
export class PersonsService extends SilverBaseService {
  constructor(
    config: EnviromentService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPersonLoggedInfo
   */
  static readonly GetPersonLoggedInfoPath = '/person';

  /**
   * Get person & beneficiaries info.
   *
   * Returns one employees logged.  
   *
   * Checks: NULL -> No open tickets, B -> Blocked, P -> Pending information.
   *
   * This method provides customization options.
   * To access only the response body, use `getPersonLoggedInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonLoggedInfo$Response(params?: {

  }): Observable<Person> {

    const req = new SilverRequestBuilder(PersonsService.GetPersonLoggedInfoPath, 'get');
    if (params) {
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Person;
      })
    );
  }

  /**
   * Get person & beneficiaries info.
   *
   * Returns one employees logged.  
   *
   * Checks: NULL -> No open tickets, B -> Blocked, P -> Pending information.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getPersonLoggedInfo$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonLoggedInfo(params?: {

  }): Observable<Person> {

    return this.getPersonLoggedInfo$Response(params).pipe(
      map(r => r as Person)
    );
  }

  /**
   * Path part for operation updatePersonContactInfo
   */
  static readonly UpdatePersonContactInfoPath = '/person/{uid}/contact-info';

  /**
   * Update person contact info.
   *
   * Update person contact info
   *
   * This method provides customization options.
   * To access only the response body, use `updatePersonContactInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePersonContactInfo$Response(params: {

    /**
     * Person UID
     */
    uid: string;
  
    /**
     * Contact info.
     */
    body: ContactInfo
  }): Observable<ContactInfo> {

    const req = new SilverRequestBuilder(PersonsService.UpdatePersonContactInfoPath, 'patch');
    if (params) {
      req.path('uid', params.uid, {});
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as ContactInfo;
      })
    );
  }

  /**
   * Update person contact info.
   *
   * Update person contact info
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `updatePersonContactInfo$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePersonContactInfo(params: {

    /**
     * Person UID
     */
    uid: string;
  
    /**
     * Contact info.
     */
    body: ContactInfo
  }): Observable<ContactInfo> {

    return this.updatePersonContactInfo$Response(params).pipe(
      map(r => r as ContactInfo)
    );
  }

  /**
   * Path part for operation updatePersonAddressInfo
   */
  static readonly UpdatePersonAddressInfoPath = '/person/{uid}/address-info';

  /**
   * Update person address info.
   *
   * Update person address info
   *
   * This method provides customization options.
   * To access only the response body, use `updatePersonAddressInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePersonAddressInfo$Response(params: {

    /**
     * Person UID
     */
    uid: string;
  
    /**
     * Address info.
     */
    body: AddressInfo
  }): Observable<AddressInfo> {

    const req = new SilverRequestBuilder(PersonsService.UpdatePersonAddressInfoPath, 'post');
    if (params) {
      req.path('uid', params.uid, {});
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as AddressInfo;
      })
    );
  }

  /**
   * Update person address info.
   *
   * Update person address info
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `updatePersonAddressInfo$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePersonAddressInfo(params: {

    /**
     * Person UID
     */
    uid: string;
  
    /**
     * Address info.
     */
    body: AddressInfo
  }): Observable<AddressInfo> {

    return this.updatePersonAddressInfo$Response(params).pipe(
      map(r => r as AddressInfo)
    );
  }

  /**
   * Path part for operation getCertificateChecks
   */
  static readonly GetCertificateChecksPath = '/person/certificateChecks';

  /**
   * Get person show certificate checks info.
   *
   * Returns one employees show certificate checks info
   *
   * This method provides customization options.
   * To access only the response body, use `getCertificateChecks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCertificateChecks$Response(params?: {

  }): Observable<CertificateChecksDto> {

    const req = new SilverRequestBuilder(PersonsService.GetCertificateChecksPath, 'get');
    if (params) {
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as CertificateChecksDto;
      })
    );
  }

  /**
   * Get person show certificate checks info.
   *
   * Returns one employees show certificate checks info
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getCertificateChecks$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getCertificateChecks(params?: {

  }): Observable<CertificateChecksDto> {

    return this.getCertificateChecks$Response(params).pipe(
      map(r => r as CertificateChecksDto)
    );
  }

  /**
   * Path part for operation getPersonSmsConsent
   */
  static readonly GetPersonSmsConsentPath = '/person/sms-consent';

  /**
   * Get person sms consent.
   *
   * Get person sms consent
   *
   * This method provides customization options.
   * To access only the response body, use `getPersonSmsConsent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonSmsConsent$Response(params?: {

  }): Observable<PersonSmsConsentDto> {

    const req = new SilverRequestBuilder(PersonsService.GetPersonSmsConsentPath, 'get');
    if (params) {
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as PersonSmsConsentDto;
      })
    );
  }

  /**
   * Get person sms consent.
   *
   * Get person sms consent
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getPersonSmsConsent$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonSmsConsent(params?: {

  }): Observable<PersonSmsConsentDto> {

    return this.getPersonSmsConsent$Response(params).pipe(
      map(r => r as PersonSmsConsentDto)
    );
  }

  /**
   * Path part for operation updatePersonSmsConsent
   */
  static readonly UpdatePersonSmsConsentPath = '/person/sms-consent';

  /**
   * Update person sms consent.
   *
   * Update person sms consent
   *
   * This method provides customization options.
   * To access only the response body, use `updatePersonSmsConsent()` instead.
   *
   * This method doesn't expect any request body.
   */
  updatePersonSmsConsent$Response(params: {

    /**
     * Accept sms flag
     */
    accept: boolean;

  }): Observable<void> {

    const req = new SilverRequestBuilder(PersonsService.UpdatePersonSmsConsentPath, 'patch');
    if (params) {
      req.query('accept', params.accept, {});
    }

    const sendReq = req.build({
      responseType: 'text',
      accept: '*/*'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return (r as void);
      })
    );
  }

  /**
   * Update person sms consent.
   *
   * Update person sms consent
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `updatePersonSmsConsent$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  updatePersonSmsConsent(params: {

    /**
     * Accept sms flag
     */
    accept: boolean;

  }): Observable<void> {

    return this.updatePersonSmsConsent$Response(params).pipe(
      map(r => r as void)
    );
  }

  /**
   * Path part for operation getDialogPersonSmsConsent
   */
  static readonly GetDialogPersonSmsConsentPath = '/person/dialog-sms-consent';

  /**
   * Get if the person sms consent dialog must be shown.
   *
   * Get if the person sms consent dialog must be shown
   *
   * This method provides customization options.
   * To access only the response body, use `getDialogPersonSmsConsent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDialogPersonSmsConsent$Response(params?: {

  }): Observable<PersonSmsConsentDto> {

    const req = new SilverRequestBuilder(PersonsService.GetDialogPersonSmsConsentPath, 'get');
    if (params) {
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as PersonSmsConsentDto;
      })
    );
  }

  /**
   * Get if the person sms consent dialog must be shown.
   *
   * Get if the person sms consent dialog must be shown
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getDialogPersonSmsConsent$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getDialogPersonSmsConsent(params?: {

  }): Observable<PersonSmsConsentDto> {

    return this.getDialogPersonSmsConsent$Response(params).pipe(
      map(r => r as PersonSmsConsentDto)
    );
  }

}
