import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'silver-sms-consent',
  templateUrl: './sms-consent.component.html',
  styleUrls: ['./sms-consent.component.scss']
})
export class SmsConsentComponent implements OnInit {

  formRef = new FormGroup({
    smsConsent: new FormControl({value: false , disabled: false}),
  });

  constructor(public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  get smsConsentValue() {
    return this.formRef.get('smsConsent').value;
  }

  markSelectedAcept() {
    this.activeModal.close(this.smsConsentValue);
  }

  openPDF() {
    const language = sessionStorage.getItem('language') || 'es';
    let pdfUrl = '../../../../assets/pdf/Consentimiento - Santander Silver ES modif.pdf';
    if (language === 'en') {
        pdfUrl = '../../../../assets/pdf/Consentimiento - Santander Silver EN modif.pdf';
    }
    window.open(pdfUrl, '_blank');
}

}
