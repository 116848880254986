<ng-container [formGroup]="parentForm">
  <button type="button" class="btn btn-secondary btn-add btn-sm" [disabled]="disabledButton()">
    {{ btnText | translate }}
    <input type="file" id="files" name="files" accept="{{ typesFiles }}" #fileInput (change)="onFileChange($event)" [disabled]="disabledButton()" />
  </button>
  <div class="add-doc-list mb-3 mt-2">
    <!-- ADD-DOC-ITEM -->
    <div class="add-doc-item" *ngFor="let file of arrayFiles?.value; let i = index">
      <i [ngClass]="[file.filetype === 'application/pdf' ? 'icn-pdf' : 'icn-doc']"></i>
      <span class="file-name" (click)="showDoc(file)">{{ file.filename }}</span>
      <i *ngIf="delete" class="icn-trash" (click)="deleteDoc(file)"></i>
    </div>
    <span class="error" *ngIf="error"> <i class="icn-alert"></i>{{ error | translate }}</span>
  </div>
</ng-container>
