/**
 * Single file for all the app's internal paths
 */
export const AppUrls = {
  Config: '/frontconfig',

  // General
  App: '',
  AppRoot: '/',

  // Home
  AppHome: 'home',
  AppHomeRoot: '',

  // Error
  AppError: 'error',

  // Success
  AppSuccess: 'success',

  AppPersonalDataAccess: 'datos-personales',
  AppContactAccess: 'contacta',
  AppDocumentationAccess: 'doc',

  AppPersonalData: 'consulta-datos',
  AppPersonalDataRoot: '',
  AppPersonalDataEdit: 'editar',
  AppPersonalAndFamilyDataEdit: 'editar-datos-familiares',
  AppSingleDataEdit: 'editar-datos',
  AppWithholding: 'certificado-ret',
  AppRetributions: 'recibo-nomina',
  AppConsultRequest: 'consulta',
  AppConsultRequestDetail: 'editar-consulta',
  AppConsultRequestRoot: '',
  AppMakeRequest: 'solicitud',
  AppMakeRequestRoot: '',

  AppLoginRoot: '',
  AppPublicLogin: 'auth',
  AppLogin: 'portal',
  AppPreLogin: 'pre-portal',

  AppRegister: 'registro',
  AppRegisterComplete: 'complete',

  AppReportDeath: 'comunicar-fallecimiento',

  AppLegals: 'legal',
  AppCookie: 'cookies',
  AppPolicy: 'politica',
  AppAdvise: 'aviso',

  AppFormacion: 'formacion',

  AppCertificateRequest: 'imp-certificados',

  AppNotifications: 'conf_notificaciones'
};

export const UrlsFaq = {
  A10: 'faq_A10',
  A11: 'faq_A11',
  A12: 'faq_A12',
  A13: 'faq_A13',
  A14: 'faq_A14',
  A15: 'faq_A15',
  A16: 'faq_A16',
  DDD: 'faq_DDD',
  D10: 'faq_D10',
  D11: 'faq_D11',
  D12: 'faq_D12',
  D13: 'faq_D13',
  D14: 'faq_D14',
  D15: 'faq_D15',
  D16: 'faq_D16'
};
