/* tslint:disable */
import { Injectable } from '@angular/core';
import { SilverBaseService } from '../silver-base-service';
import { EnviromentService } from '@core/services/environment/environment.service';
import { SilverRequestBuilder } from '../silver-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PageableTicketResponseDto } from '../models/pageable-ticket-response-dto';
import { TicketDataDto } from '../models/ticket-data-dto';
import { TicketDetailDto } from '../models/ticket-detail-dto';
import { TicketRequestDto } from '../models/ticket-request-dto';
import { TicketResponseDto } from '../models/ticket-response-dto';
import { TicketStatusUpdateRequestDto } from '../models/ticket-status-update-request-dto';
import { UpdateDataTicketDto } from '../models/update-data-ticket-dto';

@Injectable({
  providedIn: 'root',
})
export class TicketsService extends SilverBaseService {
  constructor(
    config: EnviromentService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllTicketsUsingGet
   */
  static readonly GetAllTicketsUsingGetPath = '/tickets';

  /**
   * getAllTickets.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getAllTicketsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTicketsUsingGet$Response(params: {
    'id.contains'?: string;
    'id.equals'?: string;

    /**
     * The startDate to be used in the filter
     */
    'startDate.equals'?: string;

    /**
     * The startDate to be used in the filter
     */
    'startDate.greaterThan'?: string;

    /**
     * The startDate to be used in the filter
     */
    'startDate.greaterThanOrEqual'?: string;

    /**
     * The endDate to be used in the filter
     */
    'startDate.lessThan'?: string;

    /**
     * The endDate to be used in the filter
     */
    'startDate.lessThanOrEqual'?: string;

    /**
     * The endDate to be used in the filter
     */
    'endDate.equals'?: string;

    /**
     * The endDate to be used in the filter
     */
    'endDate.lessThan'?: string;

    /**
     * The endDate to be used in the filter
     */
    'endDate.lessThanOrEqual'?: string;

    /**
     * The startDate to be used in the filter
     */
    'endDate.greaterThan'?: string;

    /**
     * The startDate to be used in the filter
     */
    'endDate.greaterThanOrEqual'?: string;
    'status.equals'?: number;
    'category.equals'?: string;
    'subcategory.equals'?: string;
    'section.equals'?: string;
    'language.equals': string;
    'type.equals'?: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

    /**
     * Sorting criteria in the format &#x3D; &#x27;property-name,(asc|desc)&#x27;. Default sort order is ascending. Multiple criteria are supported
     */
    sort?: 'id' | 'startDate' | 'endDate' | 'status' | 'category.category' | 'category.subcategory' | 'category.section';

  }): Observable<PageableTicketResponseDto> {

    const req = new SilverRequestBuilder(TicketsService.GetAllTicketsUsingGetPath, 'get');
    if (params) {
      req.query('id.contains', params['id.contains'], {});
      req.query('id.equals', params['id.equals'], {});
      req.query('startDate.equals', params['startDate.equals'], {});
      req.query('startDate.greaterThan', params['startDate.greaterThan'], {});
      req.query('startDate.greaterThanOrEqual', params['startDate.greaterThanOrEqual'], {});
      req.query('startDate.lessThan', params['startDate.lessThan'], {});
      req.query('startDate.lessThanOrEqual', params['startDate.lessThanOrEqual'], {});
      req.query('endDate.equals', params['endDate.equals'], {});
      req.query('endDate.lessThan', params['endDate.lessThan'], {});
      req.query('endDate.lessThanOrEqual', params['endDate.lessThanOrEqual'], {});
      req.query('endDate.greaterThan', params['endDate.greaterThan'], {});
      req.query('endDate.greaterThanOrEqual', params['endDate.greaterThanOrEqual'], {});
      req.query('status.equals', params['status.equals'], {});
      req.query('category.equals', params['category.equals'], {});
      req.query('subcategory.equals', params['subcategory.equals'], {});
      req.query('section.equals', params['section.equals'], {});
      req.query('language.equals', params['language.equals'], {});
      req.query('type.equals', params['type.equals'], {});
      req.query('page', params.page, {});
      req.query('size', params.size, {});
      req.query('sort', params.sort, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as PageableTicketResponseDto;
      })
    );
  }

  /**
   * getAllTickets.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getAllTicketsUsingGet$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTicketsUsingGet(params: {
    'id.contains'?: string;
    'id.equals'?: string;

    /**
     * The startDate to be used in the filter
     */
    'startDate.equals'?: string;

    /**
     * The startDate to be used in the filter
     */
    'startDate.greaterThan'?: string;

    /**
     * The startDate to be used in the filter
     */
    'startDate.greaterThanOrEqual'?: string;

    /**
     * The endDate to be used in the filter
     */
    'startDate.lessThan'?: string;

    /**
     * The endDate to be used in the filter
     */
    'startDate.lessThanOrEqual'?: string;

    /**
     * The endDate to be used in the filter
     */
    'endDate.equals'?: string;

    /**
     * The endDate to be used in the filter
     */
    'endDate.lessThan'?: string;

    /**
     * The endDate to be used in the filter
     */
    'endDate.lessThanOrEqual'?: string;

    /**
     * The startDate to be used in the filter
     */
    'endDate.greaterThan'?: string;

    /**
     * The startDate to be used in the filter
     */
    'endDate.greaterThanOrEqual'?: string;
    'status.equals'?: number;
    'category.equals'?: string;
    'subcategory.equals'?: string;
    'section.equals'?: string;
    'language.equals': string;
    'type.equals'?: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

    /**
     * Sorting criteria in the format &#x3D; &#x27;property-name,(asc|desc)&#x27;. Default sort order is ascending. Multiple criteria are supported
     */
    sort?: 'id' | 'startDate' | 'endDate' | 'status' | 'category.category' | 'category.subcategory' | 'category.section';

  }): Observable<PageableTicketResponseDto> {

    return this.getAllTicketsUsingGet$Response(params).pipe(
      map(r => r as PageableTicketResponseDto)
    );
  }

  /**
   * Path part for operation createTicketUsingPost
   */
  static readonly CreateTicketUsingPostPath = '/tickets';

  /**
   * createTicket.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `createTicketUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTicketUsingPost$Response(params?: {
      body?: TicketRequestDto
  }): Observable<TicketResponseDto> {

    const req = new SilverRequestBuilder(TicketsService.CreateTicketUsingPostPath, 'post');
    if (params) {
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as TicketResponseDto;
      })
    );
  }

  /**
   * createTicket.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `createTicketUsingPost$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTicketUsingPost(params?: {
      body?: TicketRequestDto
  }): Observable<TicketResponseDto> {

    return this.createTicketUsingPost$Response(params).pipe(
      map(r => r as TicketResponseDto)
    );
  }

  /**
   * Path part for operation getTicketById
   */
  static readonly GetTicketByIdPath = '/tickets/{ticket_id}';

  /**
   * getTicketById.
   *
   * Returns ticket according to the id
   *
   * This method provides customization options.
   * To access only the response body, use `getTicketById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTicketById$Response(params: {

    /**
     * The ticket identifier
     */
    ticket_id: string;

    /**
     * The language identifier
     */
    language: string;

  }): Observable<TicketDetailDto> {

    const req = new SilverRequestBuilder(TicketsService.GetTicketByIdPath, 'get');
    if (params) {
      req.path('ticket_id', params.ticket_id, {});
      req.query('language', params.language, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as TicketDetailDto;
      })
    );
  }

  /**
   * getTicketById.
   *
   * Returns ticket according to the id
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getTicketById$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getTicketById(params: {

    /**
     * The ticket identifier
     */
    ticket_id: string;

    /**
     * The language identifier
     */
    language: string;

  }): Observable<TicketDetailDto> {

    return this.getTicketById$Response(params).pipe(
      map(r => r as TicketDetailDto)
    );
  }

  /**
   * Path part for operation updateTicketStatusUsingPatch
   */
  static readonly UpdateTicketStatusUsingPatchPath = '/tickets/{ticket_id}';

  /**
   * updateTicketStatus.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `updateTicketStatusUsingPatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTicketStatusUsingPatch$Response(params: {

    /**
     * The ticket identifier
     */
    ticket_id: string;
      body?: TicketStatusUpdateRequestDto
  }): Observable<TicketDetailDto> {

    const req = new SilverRequestBuilder(TicketsService.UpdateTicketStatusUsingPatchPath, 'patch');
    if (params) {
      req.path('ticket_id', params.ticket_id, {});
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as TicketDetailDto;
      })
    );
  }

  /**
   * updateTicketStatus.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `updateTicketStatusUsingPatch$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTicketStatusUsingPatch(params: {

    /**
     * The ticket identifier
     */
    ticket_id: string;
      body?: TicketStatusUpdateRequestDto
  }): Observable<TicketDetailDto> {

    return this.updateTicketStatusUsingPatch$Response(params).pipe(
      map(r => r as TicketDetailDto)
    );
  }

  /**
   * Path part for operation getTicketData
   */
  static readonly GetTicketDataPath = '/ticketData/{ticket_id}';

  /**
   * getTicketData.
   *
   * Returns ticket data according to the id
   *
   * This method provides customization options.
   * To access only the response body, use `getTicketData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTicketData$Response(params: {

    /**
     * The ticket identifier
     */
    ticket_id: string;

  }): Observable<TicketDataDto> {

    const req = new SilverRequestBuilder(TicketsService.GetTicketDataPath, 'get');
    if (params) {
      req.path('ticket_id', params.ticket_id, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as TicketDataDto;
      })
    );
  }

  /**
   * getTicketData.
   *
   * Returns ticket data according to the id
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getTicketData$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getTicketData(params: {

    /**
     * The ticket identifier
     */
    ticket_id: string;

  }): Observable<TicketDataDto> {

    return this.getTicketData$Response(params).pipe(
      map(r => r as TicketDataDto)
    );
  }

  /**
   * Path part for operation updateTicketData
   */
  static readonly UpdateTicketDataPath = '/ticketData/{ticket_id}';

  /**
   * updateTicketData.
   *
   * Update ticket data according to the id
   *
   * This method provides customization options.
   * To access only the response body, use `updateTicketData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTicketData$Response(params: {

    /**
     * The ticket identifier
     */
    ticket_id: string;
      body?: UpdateDataTicketDto
  }): Observable<TicketResponseDto> {

    const req = new SilverRequestBuilder(TicketsService.UpdateTicketDataPath, 'patch');
    if (params) {
      req.path('ticket_id', params.ticket_id, {});
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as TicketResponseDto;
      })
    );
  }

  /**
   * updateTicketData.
   *
   * Update ticket data according to the id
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `updateTicketData$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTicketData(params: {

    /**
     * The ticket identifier
     */
    ticket_id: string;
      body?: UpdateDataTicketDto
  }): Observable<TicketResponseDto> {

    return this.updateTicketData$Response(params).pipe(
      map(r => r as TicketResponseDto)
    );
  }

  /**
   * Path part for operation createTicketUpdateDataUsingPost
   */
  static readonly CreateTicketUpdateDataUsingPostPath = '/updateData/tickets';

  /**
   * createTicketUpdateData.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `createTicketUpdateDataUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTicketUpdateDataUsingPost$Response(params?: {
      body?: UpdateDataTicketDto
  }): Observable<TicketResponseDto> {

    const req = new SilverRequestBuilder(TicketsService.CreateTicketUpdateDataUsingPostPath, 'post');
    if (params) {
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as TicketResponseDto;
      })
    );
  }

  /**
   * createTicketUpdateData.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `createTicketUpdateDataUsingPost$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTicketUpdateDataUsingPost(params?: {
      body?: UpdateDataTicketDto
  }): Observable<TicketResponseDto> {

    return this.createTicketUpdateDataUsingPost$Response(params).pipe(
      map(r => r as TicketResponseDto)
    );
  }

}
