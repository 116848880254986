import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

enum TypeFile {
  'pdf' = 'pdf',
  'word' = 'word',
  'any' = 'any'
}

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() checked: any;
  @Input() controlName: any;
  @Input() parentForm: FormGroup;
  @Input() formBuilder: FormBuilder;
  @Input() multipleFiles = false;
  @Input() delete = true;
  @Input() typesFile: TypeFile;
  @Input() reset = false;
  @Input() setValue: any;
  @Input() btnText: string = 'COMMON.BUTTONS.ADD_DOC';
  @Input() maxNumberOfFiles: number;
  @Input() maxSizeOfGroup = false;
  @Output() openFile: EventEmitter<any> = new EventEmitter();
  @Output() deleteFile: EventEmitter<any> = new EventEmitter();
  @Output() files: EventEmitter<any> = new EventEmitter();
  arrayFiles: FormArray;
  typesFiles: string;
  types: RegExp;
  error: string;
  maxSize: number = 10485760;
  totalArrayFiles = [];

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.parentForm.addControl('file', new FormControl(''));
    this.parentForm.addControl(this.controlName, this.fb.array([]));

    if (this.setValue) {
      this.setInitialValues(this.setValue);
    }

    switch (this.typesFile) {
      case TypeFile.word:
        this.typesFiles = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        this.types = /(doc|docx)$/i;
        break;
      case TypeFile.any:
        this.typesFiles =
          '.pdf,application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpg,image/jpeg';
        this.types = /(pdf|doc|docx|jpg|jpeg)$/i;
        break;
      default:
        this.typesFiles = '.pdf,application/pdf';
        this.types = /(pdf)$/i;
        break;
    }
  }

  ngOnDestroy() {
    const files = this.arrayFiles?.length;
    if (files > 0) {
      for (let i = 0; i < files; i++) {
        this.arrayFiles.removeAt(0);
      }
    }
  }

  setInitialValues(setValues: any) {
    if (setValues.length > 0) {
      this.arrayFiles = this.parentForm.get(this.controlName) as FormArray;
      setValues.forEach((element) => {
        this.parentForm.get('file').setValue({
          filename: element.name,
          name: element.name.split('.')[0],
          filetype: element.type,
          value: element.value
        });
        this.arrayFiles.push(new FormControl(this.parentForm.get('file').value));
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reset && changes.reset.previousValue === false && changes.reset.currentValue === true) {
      this.fileInput.nativeElement.value = '';
      this.arrayFiles = this.parentForm.get(this.controlName) as FormArray;
    }
  }

  /**
   * Carga en el array de del controlname del formulario el fichero o los ficheros que se adjuntan.
   * @param event
   */
  async onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.error = undefined;
      Promise.all(this.readFilesAndLoad(event)).then(() => {
        this.files.emit(this.arrayFiles);
      });
    }
  }

  private readFilesAndLoad(event): Promise<any>[] {
    const promiseArray: Promise<any>[] = [];

    for (const element of event.target.files) {
      const reader = new FileReader();
      const file = element;
      this.arrayFiles = this.parentForm.get(this.controlName) as FormArray;

      if (this.validateFile(file)) {
        const promise = new Promise((resolve, reject) => {
          reader.onload = () => {
            this.parentForm.get('file').setValue({
              filename: file.name,
              name: file.name.split('.')[0],
              filetype: file.type,
              value: reader.result.toString().split(',')[1]
            });
            if (!this.multipleFiles) {
              this.arrayFiles.removeAt(0);
              this.arrayFiles.push(new FormControl(this.parentForm.get('file').value));
            } else if (this.multipleFiles) {
              this.totalArrayFiles.push(file);
              this.arrayFiles.push(new FormControl(this.parentForm.get('file').value));
            }
            resolve(reader.result);
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
        promiseArray.push(promise);
      }
    }

    return promiseArray;
  }

  showDoc(doc) {
    this.openFile.emit(doc);
  }

  deleteDoc(item) {
    this.arrayFiles = this.parentForm.get(this.controlName) as FormArray;
    this.arrayFiles.removeAt(this.arrayFiles.value.findIndex((file) => file.filename === item.filename));
    this.totalArrayFiles = this.totalArrayFiles.filter((file) => file.name !== item.filename);
    if (this.arrayFiles.length === 0) {
      this.parentForm.controls[this.controlName].setValue([]);
      this.fileInput.nativeElement.value = '';
      this.totalArrayFiles = [];
    }
    this.deleteFile.emit(item);
  }

  private validateFile(file): boolean {
    const nameArray = file.name.split('.');
    if (!this.types.exec(nameArray[nameArray.length - 1]) && this.typesFile !== undefined) {
      this.deleteDoc(file);
      this.error = 'UPLOAD_FILE.TYPE_ERROR';
      return false;
    } else if (file.size > this.maxSize) {
      this.deleteDoc(file);
      this.error = 'UPLOAD_FILE.SIZE_ERROR';
    } else if (this.totalArrayFiles.length && this.maxSizeOfGroup && this.sizeOfGroup(file.size)) {
      this.error = 'UPLOAD_FILE.GROUP_SIZE_ERROR';
    } else {
      this.error = null;
      return true;
    }
  }

  sizeOfGroup(size) {
    let totalSize = size;
    this.totalArrayFiles.forEach((file) => (totalSize = totalSize + file.size));
    return totalSize > this.maxSize;
  }

  disabledButton(): boolean {
    return this.multipleFiles && this.maxNumberOfFiles && this.maxNumberOfFiles === this.arrayFiles?.length;
  }
}
