import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogoutService } from '@app/core/api/SILVER/services';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { EnviromentService } from '../environment/environment.service';
import { LoginWs } from './login.model';
import { StorageService } from '@core/services/storage/storage.service';

// import * as Login from '@assets/mocks/Login.json';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  valuesLogin: any;
  valueLanguage: string;
  collective: string;
  loginStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
  expirationSubscribe: Subscription = null;

  constructor(
    private readonly http: HttpClient,
    private readonly enviromentService: EnviromentService,
    private readonly translateService: TranslateService,
    private readonly storageService: StorageService,
    private readonly logoutService: LogoutService
  ) {}

  logout() {
    this.loginStatus.next(false);
    this.logoutService
      .logout()
      .pipe(first())
      .subscribe(
        () => {
          this.loginStatus.next(false);
        },
        () => {
          this.loginStatus.next(false);
        }
      );
  }

  launchLogin(): Promise<LoginWs> {
    const promise = new Promise((resolve, reject) => {
      this.getLoginUser()
        .then((response: LoginWs) => {
          if (response) {
            this.validateLogin(response)
              .then((validate) => {
                if (validate !== true) {
                  throw validate;
                } else {
                  this.storageService.set('authUser', true);
                  resolve(response);
                }
              })
              .catch((err) => {
                console.error('Error en la promise de validateLogin:', err);
                const status = err.status;
                const message = err.message;
                window.location.href = './assets/error.html?error=' + status + '&message=' + message;
                reject(err);
              });
          } else {
            resolve(response);
          }
        })
        .catch((err) => {
          console.error('Error en la promise de getLoginUser:', err);
          reject(err);
        });
    });
    return promise;
  }

  getLoginUser(): Promise<any> {
    const baseUrl = this.enviromentService.get().app.rest.PS.baseUrl;
    const url = this.enviromentService.get().app.rest.PS.endpoints.Login.url;
    const UrlLogin: string = baseUrl + url;
    // return of(Login['default']).toPromise();
    return this.http.post(UrlLogin, null).toPromise();
  }

  validateLogin(response) {
    let lang = 'es';
    let languageContId = 'es_ES';
    if (response?.data) {
      // idioma del usuario
      if (response.data.languageId) {
        lang = response.data.languageId;
        this.valueLanguage = response.data.languageId;
      }

      // idioma de liferay
      if (response.data.languageContId) {
        languageContId = response.data.languageContId;
      }

      // Guardamos en el sessionStorage el id estructura del usuario que se ha logado.
      if (response.data.hrStructureId) {
        sessionStorage.setItem('hrStructureId', response.data.hrStructureId.toString());
      }

      // Guardamos en el sessionStorage el idioma del usuario que se ha logado.
      if (!sessionStorage.getItem('languageUser')) {
        sessionStorage.setItem('languageUser', lang);
      }

      // Si no tenemos el idioma en sesion cogemos el idioma del login de usuario
      if (!sessionStorage.getItem('language')) {
        sessionStorage.setItem('language', lang);
      }

      // Si no tenemos el idioma de liferay en sesion cogemos el idioma del login de usuario
      if (!sessionStorage.getItem('languageContId')) {
        sessionStorage.setItem('languageContId', languageContId);
      }

      // Guardamos en el sessionStorage el id company y el colectivo del usuario que se ha logado.
      response.data.company && sessionStorage.setItem('company', response.data.company.toString());
      response.data.collective && sessionStorage.setItem('collective', response.data.collective.toString());
    }
    return this.getMessageError(response);
  }

  getMessageError(response): Promise<{}> {
    const promise = new Promise((resolve, reject) => {
      this.translateService
        .use(sessionStorage['language'])
        .toPromise()
        .then((translates) => {
          this.setErrors(response, resolve, translates, sessionStorage['language']);
        });
    });
    return promise;
  }

  private setErrors(response: any, resolve: (value: unknown) => void, translates: any, lang: string) {
    if (response.status === '400') {
      // Cuando da error de acceso (status = 400)
      resolve({
        status: response.status,
        message: lang !== '' ? translates.COMMON.ERROR.CONNECTION_ERROR : 'Error de acceso / Access error'
      });
    } else if (response.status === '401') {
      // Cuando hay error en la cookie (No recibida, formato incorrecto, expirada... (status = 401)
      resolve({
        status: response.status,
        message:
          lang !== ''
            ? translates.COMMON.ERROR.SESSION_EXPIRED
            : 'Su sesión ha caducado. Por favor, conectese de nuevo / Your session is expired. Please, login again.'
      });
    } else if (response.status === '403') {
      // Cuando no se tiene permisos (status = 403)
      resolve({
        status: response.status,
        message: lang !== '' ? translates.COMMON.ERROR.NO_PERMISSIONS : 'Sin acceso / Permission denied'
      });
    } else if (response.status !== '200') {
      // Cualquier otro tipo de error
      resolve({ status: response.status, message: response.errorMsg });
    } else {
      // Si todo ha ido bien
      this.setLogin(response);
      resolve(true);
    }
  }

  setLogin(values) {
    this.loginStatus.next(true);
    this.valuesLogin = values.data;
    this.setCollective(values.data.collective);
  }

  getLogin(): any {
    return this.valuesLogin;
  }

  setCollective(collective: string) {
    this.collective = collective;
  }

  getCollective() {
    return this.collective;
  }
}
