import { AppVersionComponent } from '@modules/app-version/app-version/app-version.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from '@app/shared/components/error/error.component';
import { AppUrls } from '@config/app-urls.config';
import { OkMsgComponent } from '@shared/components/ok-msg/ok-msg.component';
import { AuthGuardGuard } from './guards/auth-guard/auth-guard.guard';

const routes: Routes = [
  {
    path: AppUrls.AppPublicLogin,
    data: {
      forcePublicHeader: true
    },
    loadChildren: () => import('@modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: AppUrls.AppHome,
    loadChildren: () => import('@modules/home/home.module').then(m => m.HomeModule),
    canLoad: [AuthGuardGuard],
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'FirstLevelModule',
    loadChildren: () => import('@modules/first-level/first-level.module').then(m => m.FirstLevelModule),
    canLoad: [AuthGuardGuard],
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'SecondLevelModule',
    loadChildren: () => import('@modules/second-level/second-level.module').then(m => m.SecondLevelModule),
    canLoad: [AuthGuardGuard],
    canActivate: [AuthGuardGuard]
  },
  {
    path: AppUrls.AppNotifications,
    loadChildren: () => import('@modules/notifications/notifications.module').then(m => m.NotificationsModule),
    canLoad: [AuthGuardGuard],
    canActivate: [AuthGuardGuard]
  },
  {
    path: AppUrls.AppReportDeath,
    loadChildren: () => import('@modules/report-death/report-death.module').then(m => m.ReportDeathModule),
    data: {
      forcePublicHeader: true
    }
  },
  {
    path: 'FinalContentParagraphModule',
    loadChildren: () => import('@modules/final-content-paragraph/final-content-paragraph.module').then(m => m.FinalContentParagraphModule),
    canLoad: [AuthGuardGuard],
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'FinalContentAccordionModule',
    loadChildren: () => import('@modules/final-content-paragraph/final-content-paragraph.module').then(m => m.FinalContentParagraphModule),
    canLoad: [AuthGuardGuard],
    canActivate: [AuthGuardGuard]
  },
  {
    path: AppUrls.AppPersonalData,
    loadChildren: () => import('@modules/personal-data/personal-data.module').then(m => m.PersonalDataModule),
    canLoad: [AuthGuardGuard],
    canActivate: [AuthGuardGuard]
  },
  {
    path: AppUrls.AppRetributions,
    loadChildren: () => import('@modules/retributions/retributions.module').then(m => m.RetributionsModule),
    canLoad: [AuthGuardGuard],
    canActivate: [AuthGuardGuard]
  },
  {
    path: AppUrls.AppWithholding,
    loadChildren: () => import('@modules/withholding-certificate/withholding-certificate.module').then(m => m.WithholdingCertificateModule),
    canLoad: [AuthGuardGuard],
    canActivate: [AuthGuardGuard]
  },
  {
    path: AppUrls.AppConsultRequest,
    loadChildren: () => import('@modules/consult-request/consult-request.module').then(m => m.ConsultRequestModule),
    canLoad: [AuthGuardGuard],
    canActivate: [AuthGuardGuard]
  },
  {
    path: AppUrls.AppMakeRequest,
    loadChildren: () => import('@modules/make-request/make-request.module').then(m => m.MakeRequestModule),
    canLoad: [AuthGuardGuard],
    canActivate: [AuthGuardGuard]
  },
  {
    path: AppUrls.AppCertificateRequest,
    loadChildren: () => import('@modules/certificate-request/certificate-request.module').then(m => m.CertificateRequestModule),
    canLoad: [AuthGuardGuard],
    canActivate: [AuthGuardGuard]
  },
  {
    path: AppUrls.AppLegals,
    loadChildren: () => import('@modules/legal/legal.module').then(m => m.LegalModule)
  },
  {
    path: 'dummy',
    loadChildren: () => import('@modules/dummy/dummy.module').then(m => m.DummyModule)
  },
  {
    path: 'app_version', component: AppVersionComponent
  },
  {
    path: AppUrls.AppError, component: ErrorComponent
  },
  {
    path: AppUrls.AppSuccess, component: OkMsgComponent
  },
  {
    path: '',
    redirectTo: AppUrls.AppHome,
    pathMatch: 'full'
  },
  {
    path: '**', redirectTo: AppUrls.AppPublicLogin + '/' + AppUrls.AppLogin
   }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class CoreRoutingModule {
}
