import { Injectable } from '@angular/core';
import { TranslationsService } from '@core/services/translations/translations.service';
import { Location } from '@angular/common';
import { AppUrls } from '@config/app-urls.config';
import { HeaderService } from '@shell/header/header.service';

/**
 * This service will handle the required initializations and subscriptions required on app start (translations and security mainly).
 * By importing it on app.component and invoking it from there we avoid bloating that component, since
 * it should remain as empty as possible
 */
@Injectable({
  providedIn: 'root'
})
export class InitService {
  constructor(private readonly translate: TranslationsService, private location: Location, private headerService: HeaderService) {}

  init() {
    return new Promise(async (resolve) => {
      this.translate.init();
      // FIXME: review when a proper private/public mechanism has been introduced
      this.initPublicPrivateByUrl();
      resolve(true);
    });
  }

  private initPublicPrivateByUrl(): void {
    const url = this.location.path().split('/')[1];

    switch (url) {
      case AppUrls.AppPublicLogin:
      case AppUrls.AppRegister:
        this.headerService.isPublicMode.next(true);
        break;
      default:
        this.headerService.isPublicMode.next(false);
        break;
    }
  }
}
