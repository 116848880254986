import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SETTINGS } from '@app/shared/constants/settings/settings.constants';
import { BreadCrumb } from '@app/shared/models/breadcrumb/breadcrumbs.model';
import { BehaviorSubject } from 'rxjs';
import {
  TagManagerEvent,
  TagManagerPage,
  TypeEventExternal,
} from './tag-manager.model';

declare var window: any;

@Injectable()
export class TagManagerService {
  scriptSrc = '';
  public static disableAnalyt: boolean = true;
  private disableAnalyt$ = new BehaviorSubject<any>(true);

  /**
   * setea dun valor en utag_data
   * @param key key a setear en utag_data
   * @param value valor a setear en utag_data
   */
  setUtag(key, value) {
    if (window.sandatalayer === undefined) {
      this.getScript(this.scriptSrc, () => {
        window.sandatalayer[key] = value;
      });
    } else {
      window.sandatalayer[key] = value;
    }
  }

  /**
   * setea dun valor en utag_data
   * @param key key a setear en utag_datak
   * @param value valor a setear en utag_data
   */
  getUtag(key): boolean {
    if (window.sandatalayer === undefined) {
      this.getScript(this.scriptSrc, () => {
        if (window.sandatalayer[key]) {
          return true;
        }
      });
    } else {
      if (window.sandatalayer[key]) {
        return true;
      }
    }
  }

  // Generic script loader with callback
  private getScript(src: string, callback: Function) {
    const d = document;
    const o = {
      callback: callback || function () {},
    };

    if (typeof src === 'undefined') {
      return;
    }

    const s: any = d.createElement('script');
    s.language = 'javascript';
    s.type = 'text/javascript';
    s.async = true;
    s.charset = 'utf-8';
    s.src = src;
    if (typeof o.callback === 'function') {
      if (d.addEventListener) {
        s.addEventListener(
          'load',
          function () {
            o.callback();
          },
          false
        );
      } else {
        // old IE support
        // tslint:disable-next-line:max-line-length
        s.onreadystatechange = function () {
          if (this.readyState === 'complete' || this.readyState === 'loaded') {
            this.onreadystatechange = null;
            o.callback();
          }
        };
      }
    }
    const t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
  }

  // Data layer is optional set of key/value pairs
  private track(tealiumEvent: string, data?: any) {
    if (TagManagerService.disableAnalyt) {
      return false;
    }
    if (window.isjQuery) {
      try {
        if (window.sandatalayer !== undefined) {
          window.jQuery('body').trigger(tealiumEvent, data);
        }
      } catch (err) {
        console.log('Tealium propagation exception', err);
      }
    }
  }

  /**
   * Propagacion de cambio de vista
   * @param data object TagManagerPage Interface
   */
  view(data: TagManagerPage) {
    this.track('evShowView', data);
  }

  /**
   * propagacion de un evento
   * @param data object interface TagManagerPage
   */
  event(data: TagManagerEvent) {
    this.track('evSendAction', data);
  }

  /**
   * Método que devuelve la categoria de la página en la que estas
   * El dato corresponde con el modulo de primer nivel al que pertenece la página.
   * @memberof TagManagerService
   */
  getCategory(route: ActivatedRoute) {
    if (route.snapshot.data.hasOwnProperty('breadcrumb')) {
      const breadcrumb: BreadCrumb = route.snapshot.data['breadcrumb'];
      if (breadcrumb.levels) {
        return breadcrumb.levels[1].level;
      }
    }
  }

  /**
   * Método que devuelve el titulo de la página en la que estas.
   * @memberof TagManagerService
   */
  getTitle(route: ActivatedRoute, accessType: string, titlePage?: string) {
    let title = '';
    if (accessType === 'BUSC') {
      title = 'Buscador';
    } else {
      if (route.snapshot.data.hasOwnProperty('breadcrumb')) {
        const field = 'breadcrumb';
        const breadcrumb: BreadCrumb = route.snapshot.data[field];
        if (breadcrumb.h1) {
          title = breadcrumb.h1;
        }
      } else {
        title = titlePage;
      }
    }
    return title;
  }

  /**
   * Método que propaga el cambio de página visitada por el usuario
   * @param {string} url url de la pagina en la que estamos
   * @param {string} accessType tipo de acceso en el que nos econtramos
   * @param {ActivatedRoute} activatedRoute ruta activa para sacar la categoria y el titulo
   * @memberof TagManagerService
   */
  setTagManagerPage(
    url: string,
    accessType: string,
    activatedRoute: ActivatedRoute,
    titlePage?: string
  ) {
    this.setUtag('page_name', SETTINGS.TAG_MANAGER.page_name + url);
    this.setUtag(
      'page_title',
      SETTINGS.TAG_MANAGER.page_title +
        this.getTitle(activatedRoute, accessType)
    );
    this.setUtag('page_type', accessType);
    this.setUtag(
      'page_category',
      this.getCategory(activatedRoute)
        ? this.getCategory(activatedRoute)
        : accessType === 'BUSC'
        ? 'buscador'
        : ''
    );

    const data: TagManagerPage = {
      page_name: SETTINGS.TAG_MANAGER.page_name + url,
      page_title:
        SETTINGS.TAG_MANAGER.page_title +
        this.getTitle(activatedRoute, accessType, titlePage),
      page_type: accessType,
    };

    this.view(data);
  }

  /**
   *  Método que propaga los eventos de los enlaces externos al espacio del empleado tanto de People como de Liferay.
   *
   * @param {string} action
   * @param {string} category
   * @param {string} label
   * @param {string} url
   * @memberof TagManagerService
   */
  eventExternalLinks(
    label: string,
    url: string,
    type: TypeEventExternal,
    actionDoc?: string
  ) {
    let action = '';
    let category = '';
    switch (type) {
      case TypeEventExternal.MENU:
        action = SETTINGS.TAG_MANAGER.enlaces_externos_menu.action;
        category = SETTINGS.TAG_MANAGER.enlaces_externos_menu.category;
        break;
      case TypeEventExternal.LINK:
        action = SETTINGS.TAG_MANAGER.enlaces_externos_link.action;
        category = SETTINGS.TAG_MANAGER.enlaces_externos_link.category;
        break;
      case TypeEventExternal.DOC:
        action =
          SETTINGS.TAG_MANAGER.enlaces_externos_doc.action +
          (actionDoc !== '' ? ' :: ' + actionDoc : '');
        category = SETTINGS.TAG_MANAGER.enlaces_externos_doc.category;
        break;
      case TypeEventExternal.VIDEO:
        action = SETTINGS.TAG_MANAGER.enlaces_externos_video.action;
        category = SETTINGS.TAG_MANAGER.enlaces_externos_video.category;
        break;
      case TypeEventExternal.SEARCH:
        action = SETTINGS.TAG_MANAGER.busqueda.action;
        category = SETTINGS.TAG_MANAGER.busqueda.category;
        break;
    }
    const data: TagManagerEvent = {
      action,
      category,
      label,
      url,
    };
    this.event(data);
  }

  public updateDisableAnalyt(value) {
    this.disableAnalyt$.next(value);
  }

  public getDisableAnalyt() {
    return this.disableAnalyt$.asObservable();
  }

  public loadTagManagerScript(gtmAuth, gtmPreview) {
    const script = document.getElementById('gtm');
    script[
      'src'
    ] = `https://www.googletagmanager.com/gtm.js?id=GTM-MQX83ZC&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x`;

    const noscript = document.getElementsByTagName('noscript');

    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=GTM-MQX83ZC&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x`;
    iframe.height = '0';
    iframe.title = 'googletagmanager';
    iframe.width = '0';
    iframe.setAttribute('display', 'none');
    iframe.setAttribute('visibility', 'hidden');
    noscript[0].appendChild(iframe);
  }
}
