 <div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ 'LEGALS.CONSENT.TITLE' | translate }}</h4>
</div>
<div class="modal-body">
  <p> {{ 'LEGALS.CONSENT.TEXT_1' | translate }}
  </p>
    <p> {{ 'LEGALS.CONSENT.TEXT_2' | translate }}
  </p>
  <p> {{ 'LEGALS.CONSENT.TEXT_3' | translate }}
    <span class="link-txt" (click)="openPDF()">{{ 'LEGALS.CONSENT.CONSENT_TEXT' | translate }}</span>
  </p>
  <p> {{ 'LEGALS.CONSENT.TEXT_4' | translate }}
  </p>

  <form [formGroup]="formRef">
    <label class="check">
      <input type="checkbox" id="smsConsent" formControlName="smsConsent">
      <span class="checkmark checked" [ngClass]="{'checked': smsConsentValue}"></span>
      <span>{{ 'LEGALS.CONSENT.CHECKBOX_TEXT_CONSENT' | translate }}</span>
    </label>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary"
          (click)="markSelectedAcept()">{{ 'LEGALS.CONSENT.BUTTON_ACCEPT' | translate }}</button>
  </div>
