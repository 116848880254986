export const BootstrapClass = {
  classCol2: 'col-md-2 col-12',
  classCol3: 'col-md-3 col-12',
  classCol4: 'col-md-4 col-12',
  classCol5: 'col-md-5 col-12',
  classCol6: 'col-md-6 col-12',
  classCol8: 'col-md-8 col-12',
  classCol10: 'col-md-10 col-12',
  classCol12: 'col-md-12 col-12',
  bottomMargin: 'mb-5',
  classCol4Center: 'col-md-4 offset-md-4',
  classCol6Center: 'col-md-6 offset-md-3',
  classCol2Center: 'col-md-2 offset-md-4'
};
