<ng-container [formGroup]="parentForm">
  <!-- xxx:{{inputCtrl?.errors|json}} - ml:{{minLength}} -->
  <div class="search-input">
    <input class="form-control" type="search"
           [placeholder]="placeholder | translate"
           [formControlName]="controlName"
           (keydown.enter)="iconSearch($event, controlName)"
           (input)="inputChanges($event)"
           (click)="clickInput($event)"
           (clickOutside)="clickOutside();"
           [readonly]="selectType"
           [value]="inputValue | translate"/>
    <i *ngIf="!inputCtrl?.disabled && !selectType && inputCtrl?.value" class="icn-close" (click)="delete()"></i>
    <i [ngClass]="{'icn-dropdown': dropdown, 'icn-search': !dropdown}" *ngIf="!inputCtrl?.disabled && !selectType"
       (click)="iconSearch($event, controlName)"></i>

    <div class="alert-msg" *ngIf="showAlert">
      <i class="icn-alert"></i>
      <div>{{'COMMON.SEARCH_INPUT.ERROR' | translate}}</div>
    </div>

    <div *ngIf="results?.length > 0">
      <i *ngIf="!inputCtrl?.disabled && selectType && inputCtrl?.value !== null && icnClose && !disabled" class="icn-close"
         (click)="delete()"></i>
      <i class="icn-dropdown" *ngIf="!inputCtrl?.disabled && selectType && !disabled" (click)="clickInput($event)"></i>
    </div>

    <div class="search-input-dropdown" [ngStyle]="{'max-height': maximHeight ? maximHeight + 'px' : ''}"
         *ngIf="results?.length > 0 && showResults && !showDescription">
      <div class="more-result-msg" *ngIf="resultOversize" [translate]="'COMMON.FILTER.MORE_RESULTS'"
           [translateParams]="{maxSize: inputSizeSearchLimit}"></div>
      <div *ngFor="let item of results" (click)="selectValue(item)">{{ item.value | translate }}</div>
    </div>
    <div class="search-input-dropdown"
         *ngIf="results?.length > 0 && showResults && extraFieldToShow.length > 0">
      <div class="more-result-msg" *ngIf="resultOversize" [translate]="'COMMON.FILTER.MORE_RESULTS'"
           [translateParams]="{maxSize: inputSizeSearchLimit}"></div>
      <div *ngFor="let item of results" (click)="selectValue(item)">{{ item.value | translate }}
        <span *ngFor="let info of extraFieldToShow"> - {{ item[info] }}</span>
      </div>
    </div>
  </div>
</ng-container>
