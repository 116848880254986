import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FilterTableConfigEnum } from '../filter-table/filter-table.model';
import { Subject } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { CustomTableService } from '../custom-table/custom-table.service';

@Component({
  selector: '[app-row-example-check-all]',
  templateUrl: './row-example-check-all.component.html',
  styleUrls: ['./row-example-check-all.component.scss']
})
export class RowExampleCheckAllComponent implements OnInit, OnDestroy {
  @Input() row: any;
  @Input() i: number;
  @Input() parentForm: FormGroup;
  @Input() tableType: any;
  @Output() rowAction = new EventEmitter();

  tooltipPowers: string[] = [];
  tooltipEmployees: string[] = [];

  public requests = {
    employee: '',
    employeeId: '',
    companyEmployee: '',
    company: '',
    group: '',
    power: ''
  };

  public FilterTableConfigEnum = FilterTableConfigEnum;
  public pageType = '';
  public nameCtrl = '';

  private checkAll = false;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private readonly customTableService: CustomTableService) {}

  ngOnInit(): void {
    this.requests = {
      employee: this.row.employees[0].name,
      employeeId: this.row.employees[0].id,
      companyEmployee: this.row.employees[0].company?.value,
      company: this.row.powers[0].company,
      group: this.row.powers[0].group,
      power: this.row.powers[0].power
    };

    if (this.row.powers.length > 1) {
      this.row.powers.forEach((element) => {
        this.tooltipPowers.push(`${element.company} - ${element.group} - ${element.power}`);
      });
    }
    if (this.row.employees.length > 1) {
      this.row.employees.forEach((element) => {
        this.tooltipEmployees.push(`${element.id} - ${element.name} - ${element.company?.value}`);
      });
    }
    this.pageType = this.tableType;

    if (this.pageType === FilterTableConfigEnum.SCROLL_REQUEST || this.pageType === FilterTableConfigEnum.GROUP_FILTER) {
      this.createCheckCtrl();
      // Observable que pasa el estado del checkAll
      this.customTableService
        .getCheckAllObs()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((val) => {
          if (this.checkAll !== val) {
            this.checkAll = val;
            this.changeCheckbox(null, true);
          }
        });
    }
  }

  /**
   * @description Método para crear el checkbox. Evalua a través del FilterTabeService que valor debe setearse
   */
  createCheckCtrl() {
    this.nameCtrl = `checkRow${this.row.requestId}`;
    const checkValue = this.customTableService.getExistItem(this.row.requestId) !== -1 || this.customTableService.getCheckAll();
    if (!this.parentForm.controls[this.nameCtrl]) {
      this.parentForm.addControl(this.nameCtrl, new FormControl(checkValue));
    } else {
      this.parentForm.controls[this.nameCtrl].setValue(checkValue);
    }
    this.enableDisableCheck();
  }

  /**
   * @description Método
   * @param event si se pasa por parámetro el evento es un click desde la vista, si no del checkAll.
   * SI el checkAll está a true se deshabilita el checkbox.
   */
  public changeCheckbox(event, fromCheckAll?) {
    if (event) {
      this.rowAction.emit({ requestId: this.row.requestId, checked: event.target.checked, action: 'check' });
    } else if (fromCheckAll) {
      this.rowAction.emit({ requestId: this.row.requestId, checked: this.checkAll, action: 'check' });
    }
    this.enableDisableCheck();
  }

  enableDisableCheck() {
    const checkAll = 'checkAll';
    if (this.parentForm.controls[checkAll]) {
      if (this.parentForm.controls[checkAll].value) {
        this.parentForm.controls[this.nameCtrl].disable();
      } else {
        this.parentForm.controls[this.nameCtrl].enable();
      }
    }
  }

  rowEdit(event) {
    this.rowAction.emit({ requestId: this.row.requestId, itemRow: this.row, action: 'edit' });
  }

  rowRemove(event) {
    this.rowAction.emit({ requestId: this.row.requestId, itemRow: this.row, action: 'remove' });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
