/* tslint:disable */
import { Injectable } from '@angular/core';
import { SilverBaseService } from '../silver-base-service';
import { EnviromentService } from '@core/services/environment/environment.service';
import { SilverRequestBuilder } from '../silver-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CivilStatusDto } from '../models/civil-status-dto';
import { FamilyRelationShipStatusDto } from '../models/family-relation-ship-status-dto';
import { PageableCivilStatusDto } from '../models/pageable-civil-status-dto';
import { PageableFamilyRelationshipDto } from '../models/pageable-family-relationship-dto';
import { PageableFamilyStatusDto } from '../models/pageable-family-status-dto';

@Injectable({
  providedIn: 'root',
})
export class DropdownListsService extends SilverBaseService {
  constructor(
    config: EnviromentService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllCivilStatus
   */
  static readonly GetAllCivilStatusPath = '/civil-status';

  /**
   * Get all civil status.
   *
   * Returns all civil status.
   *
   * This method provides customization options.
   * To access only the response body, use `getAllCivilStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCivilStatus$Response(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<PageableCivilStatusDto> {

    const req = new SilverRequestBuilder(DropdownListsService.GetAllCivilStatusPath, 'get');
    if (params) {
      req.query('language', params.language, {});
      req.query('page', params.page, {});
      req.query('size', params.size, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as PageableCivilStatusDto;
      })
    );
  }

  /**
   * Get all civil status.
   *
   * Returns all civil status.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getAllCivilStatus$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCivilStatus(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<PageableCivilStatusDto> {

    return this.getAllCivilStatus$Response(params).pipe(
      map(r => r as PageableCivilStatusDto)
    );
  }

  /**
   * Path part for operation getCivilStatusById
   */
  static readonly GetCivilStatusByIdPath = '/civilStatus/{civilStatusId}';

  /**
   * Get civil status by id.
   *
   * Returns civil status by id.
   *
   * This method provides customization options.
   * To access only the response body, use `getCivilStatusById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCivilStatusById$Response(params: {

    /**
     * Civil status id
     */
    civilStatusId: number;

    /**
     * Translation language
     */
    language: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<CivilStatusDto> {

    const req = new SilverRequestBuilder(DropdownListsService.GetCivilStatusByIdPath, 'get');
    if (params) {
      req.path('civilStatusId', params.civilStatusId, {});
      req.query('language', params.language, {});
      req.query('page', params.page, {});
      req.query('size', params.size, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as CivilStatusDto;
      })
    );
  }

  /**
   * Get civil status by id.
   *
   * Returns civil status by id.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getCivilStatusById$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getCivilStatusById(params: {

    /**
     * Civil status id
     */
    civilStatusId: number;

    /**
     * Translation language
     */
    language: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<CivilStatusDto> {

    return this.getCivilStatusById$Response(params).pipe(
      map(r => r as CivilStatusDto)
    );
  }

  /**
   * Path part for operation getAllFamilyStatus
   */
  static readonly GetAllFamilyStatusPath = '/family-status';

  /**
   * Get all family status.
   *
   * Returns all family status.
   *
   * This method provides customization options.
   * To access only the response body, use `getAllFamilyStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFamilyStatus$Response(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<PageableFamilyStatusDto> {

    const req = new SilverRequestBuilder(DropdownListsService.GetAllFamilyStatusPath, 'get');
    if (params) {
      req.query('language', params.language, {});
      req.query('page', params.page, {});
      req.query('size', params.size, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as PageableFamilyStatusDto;
      })
    );
  }

  /**
   * Get all family status.
   *
   * Returns all family status.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getAllFamilyStatus$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFamilyStatus(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<PageableFamilyStatusDto> {

    return this.getAllFamilyStatus$Response(params).pipe(
      map(r => r as PageableFamilyStatusDto)
    );
  }

  /**
   * Path part for operation getAllFamilyRelationship
   */
  static readonly GetAllFamilyRelationshipPath = '/family-relationship';

  /**
   * Get all family relationship.
   *
   * Returns all family relationship.
   *
   * This method provides customization options.
   * To access only the response body, use `getAllFamilyRelationship()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFamilyRelationship$Response(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<PageableFamilyRelationshipDto> {

    const req = new SilverRequestBuilder(DropdownListsService.GetAllFamilyRelationshipPath, 'get');
    if (params) {
      req.query('language', params.language, {});
      req.query('page', params.page, {});
      req.query('size', params.size, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as PageableFamilyRelationshipDto;
      })
    );
  }

  /**
   * Get all family relationship.
   *
   * Returns all family relationship.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getAllFamilyRelationship$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFamilyRelationship(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<PageableFamilyRelationshipDto> {

    return this.getAllFamilyRelationship$Response(params).pipe(
      map(r => r as PageableFamilyRelationshipDto)
    );
  }

  /**
   * Path part for operation getAllFamilyRelationshipStatus
   */
  static readonly GetAllFamilyRelationshipStatusPath = '/family-relationship-status';

  /**
   * Get all family relationship status.
   *
   * Returns all family relationship status.
   *
   * This method provides customization options.
   * To access only the response body, use `getAllFamilyRelationshipStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFamilyRelationshipStatus$Response(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<FamilyRelationShipStatusDto> {

    const req = new SilverRequestBuilder(DropdownListsService.GetAllFamilyRelationshipStatusPath, 'get');
    if (params) {
      req.query('language', params.language, {});
      req.query('page', params.page, {});
      req.query('size', params.size, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as FamilyRelationShipStatusDto;
      })
    );
  }

  /**
   * Get all family relationship status.
   *
   * Returns all family relationship status.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getAllFamilyRelationshipStatus$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFamilyRelationshipStatus(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<FamilyRelationShipStatusDto> {

    return this.getAllFamilyRelationshipStatus$Response(params).pipe(
      map(r => r as FamilyRelationShipStatusDto)
    );
  }

}
